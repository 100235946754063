import { IFormField } from '@noissue-ui-kit/oldBranding/form'
import { IAuthPageContent, TAuthFlow } from 'types/authPages.types'
import { signIn } from 'next-auth/client'
import { getRedirectAfterLoginUrl } from './utilities'

export const getAuthCopyForUserFlow = ({
  userFlow,
  pageContent,
}: {
  userFlow?: TAuthFlow
  pageContent: IAuthPageContent
}) => {
  if (userFlow) {
    return pageContent.pageHeader[userFlow] || pageContent.pageHeader?.default
  }
  return pageContent.pageHeader?.default
}

export const getFormFields = (
  fieldsSettings: IFormField[],
  fieldsCopy: { [key: string]: { placeholder: string } }
): IFormField[] => {
  return fieldsSettings.map((fieldConf) => {
    const fieldName = fieldConf.name
    const defaultPaceholder = fieldConf.placeholder
    const customizedPlaceholder =
      fieldsCopy && fieldsCopy[fieldName]?.placeholder

    return {
      ...fieldConf,
      placeholder: customizedPlaceholder || defaultPaceholder,
    }
  })
}
export interface IGraphQlCustomer {
  entityId: string
  firstName: string
  lastName: string
  email: string
  customerGroupId: number
  isNewCustomer?: boolean
}

export const graphqlCustomerFromRestCustomer = (customer): IGraphQlCustomer => {
  return customer
    ? {
        entityId: customer.id,
        firstName: customer.first_name,
        lastName: customer.last_name,
        email: customer.email,
        customerGroupId: customer.customer_group_id,
      }
    : null
}

export const signInWithKeycloack = async ({
  redirectRouteParameter,
  skipProfile = false,
}: {
  redirectRouteParameter: string
  skipProfile?: boolean
}) => {
  const redirectAfterLoginUrl = getRedirectAfterLoginUrl(
    redirectRouteParameter,
    skipProfile
  )

  const callbackUrl = `${window.location.origin}${redirectAfterLoginUrl}`
  await signIn('keycloak', {
    callbackUrl,
  })
}

export const signInWithOneTap = async ({
  credential,
  redirectRouteParameter,
}: {
  credential: any
  redirectRouteParameter: string
}) => {
  const redirectAfterLoginUrl = getRedirectAfterLoginUrl(redirectRouteParameter)
  const callbackUrl = `${window.location.origin}${redirectAfterLoginUrl}`
  await signIn('googleonetap', {
    credential,
    callbackUrl,
  })
}
